.body{
    XXXbackground: url(http://www.elearnchess.com/chessV2/ressources/img/home.jpg) no-repeat; width: 100%;background-size: 100%;
    width: 100wh;
    height: 100vh;
}

.video{
    display: flex;
    Xwidth:80wh;
    height:   calc(100vh - 53px);
    text-align: center;
}


