a:link {color:#95CBB7;}              /* Lien non visité */
a:visited {color:#95CBB7;}          /* Lien visité */
a:hover {color:#B744ED;}            /* Lien survolé*/
a:active {color:#B744ED;}           /* Lien sélectionné */




.choix1{color: #95CBB7}

.amande   {color: #95CBB7;}
.violet   {color: #B744ED; } 

.oldcolor { color:  #4c5f6d;}
.fondblanc{ background:#ffffff; }
.fondcolor{ background:#5e7c87; }
.fondcolorAmmande{ background:#95CBB7; }
.fondsousmenu{ background:#f0f3f5; }
.color    { color:#4c5f6d; }
.erreur   { color:red; font-weight: bold;}
.info     { color: green; }
.infoOrange{ background-color: #B744ED; margin:2%; color:white; font-size: 120%;font-weight: bold; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.blocOrange{ background-color: #95CBB7;  color:white;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.fontblanc{ color: white; }
.fontblanc:link{ color: white; }
.fontblanc:visited{ color: white; }
.fontblanc:hover{ color: white; }
.fontblanc:active{ color: white; }



.m2px      { margin: 2px; }
.m1       { margin: 1%; }
.m5px     { margin: 5px; }
.m5       { margin: 5%; }
.mx5      { margin: 0 5vw; }
.fdc      { display: flex;flex-direction: column;    }
.fdcr     { display: flex;flex-direction: column-reverse; }
.fdr      { display: flex;flex-direction: row; }
.fdrr     { display: flex;flex-direction: row-reverse; }
.flexjustifycontentspace{ justify-content: space-between;}

.size120  {font-size: 120%;}
.souligne {  border-bottom: 1px solid #5e7c87!important;}


.w1px     { width: 1px; }
.w100     { width: 100% }
.w50      { width: 50% }
.w80      { width: 80% }
.w95      { width: 95% }
.h100vh   { height: 100vh; }
.h85vh    { height: 85vh; }
.h75vh    { height: 75vh; }
.h65vh    { height: 65vh; }
.h50vh    { height: 50vh; }
.h53px    { height: 53px; }
.h100pc   { height: 100%; }  
.h12px    { height: 12px; }  
.h4px     { height: 4px;}

.minl4px  { width: 4px }
.maxh88vh { max-height: 88vh; overflow-y: auto;overflow-x: hidden; }
.maxh75vh { max-height: 75vh; overflow-y: auto;overflow-x: hidden; }
.maxh60vh { max-height: 60vh; overflow-y: auto;overflow-x: hidden; }

.minih45vh {min-height : 45vh;}

.overflowy { overflow-y: auto; }

.nodisplay{ display:none}
.nodisplaya5:nth-child(n+6) {display:none}
.nodisplaya3:nth-child(n+4) {display:none}


.pointer:hover { 
    cursor:pointer; 
}

.mailto:hover{
    text-decoration: none;
    Xcolor: #95CBB7;
    font-style: italic;
    }
.noeffetsurvol:hover{
    text-decoration: none;
}

/** right --> text-right */




.btnendgauche{ 
    padding: 5px;
    padding-right: 10px; 
    padding-left: 10px; 
    margin-top: auto;
    margin-left: auto;
    border: 1px solid #8AC007;
    background-color: #4c5f6d;
    color: white;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    font-weight: bold;
    font-size: 120%;
}
.btnendgauche:hover{
    background-color: red
} 


.rondplus{
    font-size: 24px;
    xxcolor: #B744ED;
    color: #95CBB7;
    
}

.rondplus:hover { 
    color: #95CBB7; 
    background-color: transparent; 
    cursor:pointer; 
}

.rondclose{
    color:#5e7c87;
    font-size: 40px;
    margin-top: auto;
    margin-left: auto;
}

.rondclose:hover { 
    color: #B744ED; 
    background-color: transparent; 
    cursor:pointer; 
    text-decoration: none;
}



.carre{ 
    padding: 7px;
    padding-right: 10px; 
    padding-left: 10px; 
    margin: auto;
    margin: auto;
    border: 1px solid #8AC007;
    XXbackground-color: #5e7c87;
    background-color: #95CBB7;
    color: white;
    min-width: 38px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-weight: bold;
}

.rond{ 
    padding: 7px;
    padding-right: 10px; 
    padding-left: 10px; 
    margin: auto;
    margin: auto;
    border: 1px solid #8AC007;
    XXbackground-color: #5e7c87;
    background-color: #95CBB7;
    color: white;
    min-width: 38px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-weight: bold;
    cursor:pointer; 
}
.rond:hover{
    background-color: #B744ED;
    color :white ;
    text-decoration: none;
} 

.rondreverse{ 
    padding: 7px;
    padding-right: 10px; 
    padding-left: 10px; 
    margin: auto;
    margin: auto;
    border: 2px solid #4c5f6d;
    background-color: white;
    color: #4c5f6d;
    min-width: 38px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-weight: bold;
}
.rondreverse:hover{
    background-color: #B744ED;
    color : white ;
    text-decoration: none;
} 




li:hover {
    XXbackground-color: #e0e0e0;
    background-color: #95CBB7;
    margin-left: 1px;
    color: white;
    cursor:pointer; 
  }
 


input {
    background-color:transparent;
    border: 0px solid;
    width:80%;
    border-bottom: 2px solid #4c5f6d;
}
input:focus {
    outline:none;
    background-color: #b2d3c2		;
    XXbackground-color: #95CBB7		;
    box-shadow: 0px 0px 3px 3px #e0e0e0;
    border-bottom: 1px solid #32612d;
}


.myform {
    background-color:transparent;
    border: 0px solid;
    border-bottom: 2px solid #4c5f6d;
}

.myform:focus {
    outline:none;
    background-color: #b2d3c2		;
    box-shadow: 0px 0px 3px 3px #e0e0e0;
    border-bottom: 1px solid #32612d;
}

 

select {
    background-color:transparent;
    border: 0px solid;
    Xwidth:80%;
    Xborder-bottom: 2px solid #4c5f6d;
}
select:focus {
    outline:none;
    XXbackground-color: #e0e0e0		;
    box-shadow: 0px 0px 1px 1px #e0e0e0;
    border-bottom: 1px solid #32612d;
}



 .ombreb{
    box-shadow: 0px 0px 3px 3px #E0E0E0;
}


.logopng     {
    /*background-image: url(/logo.png) ;*/
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height:40px;
}


.retour {
    margin-left: 4%;
    color: #B744ED !important;
    XXfont-family: 'Indie Flower', cursive;
   -webkit-animation: mypulse 10s infinite;/*  Chrome, Safari, Opera  */
    animation: mypulse 10s infinite; 
    font-size: 200%;
    XXtext-shadow: 2px 2px #e0e0e0;
    font-weight: bold;
    text-decoration: none !important;
}


.logo {
    margin-left: 4%;
    font-family: 'Indie Flower', cursive;
   -webkit-animation: mypulse 10s infinite;/*  Chrome, Safari, Opera  */
    animation: mypulse 10s infinite; 
    font-size: 200%;
    XXtext-shadow: 2px 2px #e0e0e0;
    font-weight: bold;
}
.logo:hover  {
    text-decoration: none;
}

/* Chrome, Safari, Opera */ 
@-webkit-keyframes mypulse {
    0% {color:  #95CBB7;}
   50% {color: #95CBB7;}
  100% {color:  #95CBB7;}
 }
 
 @keyframes mypulse {
    0% {color:  #95CBB7;}
    50% {color:#95CBB7}
   100% {color:  #95CBB7;}
 }
 
/* Chrome, Safari, Opera */ 
@-webkit-keyframes mypulse2 {
    0% {color:  #5e7c87;
        text-shadow: 1px 1px 2px black, 0 0 25px #5e7c87, 0 0 5px #5e7c87;
    }
   50% {
       
       text-shadow: 1px 1px 2px black, 0 0 25px #95CBB7, 0 0 5px #95CBB7;
    }
  100% {
    text-shadow: 1px 1px 2px black, 0 0 25px #5e7c87, 0 0 5px #5e7c87;  
    }
 }
 
 @keyframes mypulse2 {
    0% {color:  #5e7c87;
        text-shadow: 1px 1px 2px black, 0 0 25px #5e7c87, 0 0 5px #5e7c87;
    }
   50% {
       
       text-shadow: 1px 1px 2px black, 0 0 25px #95CBB7, 0 0 5px #95CBB7;
    }
  100% {
    text-shadow: 1px 1px 2px black, 0 0 25px #5e7c87, 0 0 5px #5e7c87;  
    }
 }
 



 .fondimage{
    background: url(http://www.elearnchess.com/chessV2/ressources/img/home.jpg) no-repeat; width: 100%;background-size: 100% ;
    
}

.homephotomobile{
    background: url(http://www.elearnchess.com/chessV2/ressources/img/home.jpg) no-repeat; width: 100%;background-size: 100%;
    XXbackground: url(http://www.elearnchess.com/seminaire/ressources/img/seminaire-entreprise.jpg) no-repeat; width: 100%;background-size: 100%;
    margin-top: -15px;
    min-height : 270px;
}



.homephoto{
    background: url(http://www.elearnchess.com/chessV2/ressources/img/home.jpg) no-repeat; width: 100%;background-size: 100%;
    XXbackground: url(http://www.elearnchess.com/seminaire/ressources/img/seminaire-entreprise.jpg) no-repeat; width: 100%;background-size: 100%;
    height: 45vh;
}
.homephototexte{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12vh 0;
}

.homephotopolice{
    padding-left: 4%;
    padding-right: 4%;
    Xbackground-color: #4c5f6d;
    Xcolor: #ffffff;
    Xopacity: 0.75;
    font-size: 4vw;
    font-weight: bold;
    color: #95CBB7;
    XXtext-shadow: 1px 1px 2px black, 0 0 25px #B744ED, 0 0 5px #B744ED;
    text-shadow: -2px 0 #5e7c87, 0 2px #5e7c87,     2px 0 #5e7c87, 0 -2px #5e7c87;
    Xanimation: mypulse2 10s infinite; 
}
.homephotomobilepolice{
    padding-left: 2%;
    padding-right: 2%;
    font-size: 7vw;
    font-weight: bold;
    Xcolor: #ffffff;
    color: #5e7c5e;
    XXtext-shadow: 1px 1px 2px #5e7c87, 0 0 25px #5e7c87, 0 0 5px #5e7c87;
    text-shadow: 2px 2px 4px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff;

}
.homephotomobilepolice2{
    padding-left: 2%;
    padding-right: 2%;
    font-size: 5vw;
    font-weight: bold;
    Xcolor: #ffffff;
    color: #5e7c5e;
    XXtext-shadow: 1px 1px 2px #5e7c87, 0 0 25px #5e7c87, 0 0 5px #5e7c87;
    text-shadow: 2px 2px 4px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff;
    XXtext-align: justify;
}


/*********************************************************************/
/* Feuille de style  mobile */
/*********************************************************************/
@media (max-width: 799px) {
	.formatnormal{
		display: none;
    }
    .bordure{
        padding:4px; 
        color: #4c5f6d;
    }
    
    .bordermobile{
        margin-top: 5px;
        padding:4px; 
        color: #4c5f6d;
        border: 2px solid #e0e0e0;
    }
 
    .m2       { margin: 1%; }
}

/*********************************************************************/
@media (min-width: 800px) {
	
	.formatmobile{
		display: none;
    }
    .bordure{
        padding:4px; 
        border: 2px solid #e0e0e0;
        border-radius: 5px;
        color: #4c5f6d;
    }
    .rw50      { width: 50% }
    
    .mainbloc{  width: 100%;     height: calc(100vh - 53px); overflow-y: auto ;overflow-x: hidden ;    }
    .maxh100vh { max-height: 100vh;   }
    .noscroll { overflow-y: hidden;}

    .m2       { margin: 2%; }
}    

 
 

 ::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #95CBB7; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #95CBB7; 
  }



 