.blocHome{
    padding:4px; 
    margin-bottom:8px;
    border: 2px solid #95CBB7;
    border-radius: 3px;
    box-shadow: 4px 3px 3px#95CBB7;
}

.blocHome:hover{
    margin:2px;
    box-shadow: 2px 1px 1px#95CBB7;
    cursor: pointer;
}

.bloctexte{
    font-size: 110%;
    Xfont-weight: bold;
}




.bloc{
    margin: 4px auto;
    height: 40vh;
    min-width: 25vw;
    
}


.parent {
    width: 100%;
    height:  calc(100vh - 50px);
    display: flex;
}
.child {
    width: 100%;
    xxmargin: auto auto;
    display: flex;
    flex-flow: row wrap;

}

 

.maintitre{
    XXmargin-top:-7px;
    XXmargin-left: -7px;
    XXcolor:  #5e7c87;
    color:  #B744ED;
    background-color: #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 125%;
    XXwidth: calc(100% + 14px);
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-top-left-radius: 2px;
    -moz-border-top-right-radius: 2px;
    
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    XXborder-bottom: 1px solid #5e7c87!important;
 
    height: 38px;
    Xborder-color:  #5e7c87;
    Xborder-style: solid;
    font-weight: bold;
}

.maintitre:hover{


    color: #95CBB7;
    Xbackground-color: #5e7c87;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 125%;

    XXborder-bottom: 1px solid #95CBB7!important;

}






.mainbouton{
    margin-right: auto;
    margin-left: auto;
    color: #5e7c87;
    background-color: white;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 110%;
    width: 40%;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    border-style: solid;
    border-color:  #5e7c87;
}
 


ul.ba {
    Xwidth: 0;
    list-style-type: none;
}